import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { components } from './components/index';
import { directives } from './directives/index';
import { portalServices } from './services/index';
import { routesModule } from './portal.routes';
import { OrganizationModule, TimeclockDataService } from '../organization/index';
import { CommonModule as CommonAppModule } from '../common/index';
import { GroupSmsComponent, AssignPayPolicyComponent, CreateESSPolicyComponent, AssignPayCyclesComponent } from '../employee/employee-list/components/index';
import { ModalService } from '../common/services/index';
import { EmployeeListModule } from '../employee/employee-list/index';
import { EmployeeSectionsModule } from '../employee/employee-sections/index';
import { SchedulerModule } from '../scheduler/index';
import { AuthenticationModule } from '../authentication/authentication.module';
import { ComponentsLibraryModule } from '../components-library/index';
import { ScheduleConsoleModule } from '../scheduler/schedule-console/schedule-console.module';
import { CensusEntriesComponent } from '../scheduler/components/index';
import { ConfirmDialogComponent, InfoDialogComponent } from '../common/components/index';
import { PageScrollConfig } from '../common/models/index';
import { UserApplication } from '../state-model/models/index';
import { EmployeeSearchModule } from '../employee/employee-search/index';
import { ConfigurationModule } from '../configuration/index';
import { TimeAndAttendaceModule } from '../time-and-attendance/index';
import { ReportsModule } from '../reports/index';
import { AuditTrailModule } from '../app-modules/audit-trail/index';
import { ExportDataModule } from '../app-modules/export-data/index';
import { PbjModule } from '../app-modules/pbj/index';
import { LeaveManagementModule } from '../app-modules/leave-management/index';
import { WorkersCompensationModule } from '../app-modules/workers-compensation/index';
import { PerformanceManagementModule } from '../app-modules/performance-management/index';
import { ApprovalsModule } from '../app-modules/approvals/index';
import { PayrollModule } from '../app-modules/payroll/payroll.module';

import { ACTION_PROVIDERS } from './actions/index';
import { EPIC_PROVIDERS, ApplicationEpics, SidebarEpics } from './epics/index';
import { rootEpics } from '../state-model/state-model.module';

import { IApplication } from '../authentication/store/index';
import { BreadcrumbStateService, ServerFilterService, ThrottlingService, TokenValidityService } from '../core/services/index';
import { BreadcrumbValueResolverService } from './services/breadcrumb/breadcrumb-value-resolver.service';
//import { AngularPageVisibilityModule } from 'angular-page-visibility';
import { CoreModule } from '../core/index';
import { breadcrumpbConfig } from './portal.config';

import { Observable } from 'rxjs/Observable';

import { mutableSelect } from '../core/decorators/index';
import { MomentModule } from 'angular2-moment';
import { ApplicationStateManagementService, UrlStateManagementService } from './services/index';
import { NgxPopperModule } from 'ngx-popper';
import { IntegrationModule } from '../app-modules/logi-integration/logi-integration.module';
import { EssTemplatesModule } from '../app-modules/ess-templates/ess-templates.module';
import { StaffingPredictorModule } from '../app-modules/staffing-predictor/staffing-predictor.module';
import { PunchAttestationModule } from '../app-modules/punch-attestation/punch-attestation.module';
import { BenefitsModule } from '../app-modules/benefits/benefits.module'
import { AccrualsModule } from '../app-modules/accruals/index';
import { DashboardAlertsSelectPopupComponent } from './components/dashboard-alerts/dashboard-alerts-select-popup/dashboard-alerts-select-popup.component';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { DashboardAlertsSelectPopupDialogComponent } from './components/dashboard-alerts/dashboard-alerts-select-popup-dialog/dashboard-alerts-select-popup-dialog.component';
import { ApplicationsConfigDialogComponent } from './components/applications-dashboard/applications-config-dialog/applications-config-dialog.component';
import { ApplicationsConfigComponent } from './components/applications-dashboard/applications-config/applications-config.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { MessageCenterModule } from '../app-modules/message-center/message-center.module';
import { PunchProfileModule } from '../app-modules/punch-profile/punch-profile.module';
import { PbjExportLogManagementService } from '../app-modules/pbj/services';
import { PbjSignalrService } from './services/pbj-signalr/pbj-signalr.service';
import { TaSignalrService } from '../time-and-attendance/services/signalR/ta-signalr-service';


@NgModule({
  imports: [
    CommonModule,
    ComponentsLibraryModule,
    CommonAppModule,
    AuthenticationModule,
    EmployeeListModule,
    EmployeeSectionsModule,
    OrganizationModule,
    routesModule,
    SchedulerModule,
    FormsModule,
    // AngularPageVisibilityModule,
    CoreModule,
    ScheduleConsoleModule,
    EmployeeSearchModule,
    ConfigurationModule,
    TimeAndAttendaceModule,
    ReportsModule,
    AuditTrailModule,
    PbjModule,
    LeaveManagementModule,
    WorkersCompensationModule,
    PerformanceManagementModule,
    ApprovalsModule,
    MomentModule,
    NgxPopperModule,
    IntegrationModule,
    PayrollModule,
    EssTemplatesModule,
    StaffingPredictorModule,
    PunchAttestationModule,
    BenefitsModule,
    AccrualsModule,
    ExportDataModule,
    SortableModule,
    GridModule,
    MessageCenterModule,
    PunchProfileModule
  ],
  declarations: [...components, ...directives, DashboardAlertsSelectPopupDialogComponent, ApplicationsConfigDialogComponent, ApplicationsConfigComponent],
  entryComponents: [
    GroupSmsComponent,
    CensusEntriesComponent,
    ConfirmDialogComponent,
    InfoDialogComponent,
    AssignPayPolicyComponent,
    CreateESSPolicyComponent,
    AssignPayCyclesComponent,
    DashboardAlertsSelectPopupComponent,
    DashboardAlertsSelectPopupDialogComponent,
    ApplicationsConfigDialogComponent,
    ApplicationsConfigComponent
  ],
  providers: [...portalServices, ModalService, EPIC_PROVIDERS, ACTION_PROVIDERS, TimeclockDataService, ThrottlingService, TokenValidityService, CommonAppModule, PbjExportLogManagementService, ServerFilterService, PbjSignalrService,TaSignalrService],
  exports: []
})
export class PortalModule {


  private breadcrumbService: BreadcrumbStateService;

  constructor(
    appStateManagementService: ApplicationStateManagementService,
    urlStateManagementService: UrlStateManagementService,
    applicationEpics: ApplicationEpics,
    sidebarEpics: SidebarEpics,
    breadcrumbService: BreadcrumbStateService,
    breadcrumbValueResolverService: BreadcrumbValueResolverService
  ) {
    this.breadcrumbService = breadcrumbService;
    this.breadcrumbService.configure(breadcrumpbConfig);
    this.breadcrumbService.addNameCallback('application', (url: string, urlItem: string) => breadcrumbValueResolverService.getApplicationName(url, urlItem));
    this.breadcrumbService.addIconCallback('application', (url: string, urlItem: string) => breadcrumbValueResolverService.getApplicationIcon(url, urlItem));
    this.breadcrumbService.addIsHiddenCallback('application', (url: string, urlItem: string) => breadcrumbValueResolverService.getApplicationIsHidden(url, urlItem));
    this.breadcrumbService.addNameCallback('employeeId', (url: string, urlItem: string) => breadcrumbValueResolverService.getEmployeeName(url, urlItem));
    this.breadcrumbService.addTransformCallback('employeeId', (url: string) => breadcrumbValueResolverService.getEmployeeNameTransform(url));
    this.breadcrumbService.addNameCallback('date', (url: string, urlItem: string) => breadcrumbValueResolverService.formatDate(url, urlItem));
    this.breadcrumbService.addNameCallback('shift_date', (url: string, urlItem: string) => breadcrumbValueResolverService.formatDate(url, urlItem));
    this.breadcrumbService.addTransformCallback('shift_date', (url: string) => breadcrumbValueResolverService.getShiftDateTransform(url));
    this.breadcrumbService.addNameCallback('userId', (url: string, urlItem: string) => breadcrumbValueResolverService.getUserName(url, urlItem));
    this.breadcrumbService.addTransformCallback('userId', (url: string) => breadcrumbValueResolverService.getUserNameTransform(url));


    PageScrollConfig.defaultDuration = 700;
    rootEpics.push(applicationEpics.selectApplication);
    //rootEpics.push(sidebarEpics.menuItemChanged);
  }
}
